<template>
  <app-chip size="small" :style="{ backgroundColor, color: text }">
    {{ tag }}
  </app-chip>
</template>

<script lang="ts" setup>
const properties = defineProps<{
  tag: string;
}>();

const { background, text } = useBlogTagColors(properties.tag);
const backgroundColor = hexToHSL(background, { luminance: 93 });
</script>
