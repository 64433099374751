<template>
  <app-card-link-background
    class="h-full"
    :target="target"
    :to="{ name: 'blog-id', params: { id: post.id } }"
  >
    <section class="bg-white" :class="{ 'rounded border': outlined }">
      <app-image
        v-if="post.og_image"
        alt=""
        class="mb-4 aspect-[1.8] rounded object-cover"
        :class="{ 'rounded-b-none': outlined }"
        :height="800"
        :src="post.og_image"
        :width="800"
      />

      <div :class="{ 'px-3 pb-3': outlined }">
        <div class="mb-2 flex items-center justify-between gap-2">
          <blog-tag v-if="!!post.tags?.[0]" :tag="post.tags[0]" />
          <p class="text-xs text-subtle">
            {{ formattedDate }}
          </p>
        </div>

        <component :is="titleLevel" class="line-clamp-3 font-semibold">
          {{ post.title }}
        </component>
      </div>
    </section>
  </app-card-link-background>
</template>

<script lang="ts" setup>
import type { BlogPostPage } from "~~/model/blog.model";

const properties = withDefaults(
  defineProps<{
    post: BlogPostPage;
    target?: "_blank";
    titleLevel?: `h${2 | 3 | 4 | 5 | 6}`;
    outlined?: boolean;
  }>(),
  {
    target: undefined,
    titleLevel: "h3",
  },
);

const formattedDate = useI18nDate(properties.post.created_at);
</script>
